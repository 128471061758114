// Polyfills
import objectFitImages from 'object-fit-images';
import 'promise-polyfill/src/polyfill';

// Import our CSS
import styles from '../css/app.pcss';

import lozad           from 'lozad';
import Swiper          from 'swiper';

window.lozadObserver = lozad('.lozad', {
    loaded: function (el) {
        const cropPos = el.getAttribute('data-crop-pos');
        if (cropPos) {
            const img = el.getElementsByTagName('img');
            if (img) {
                img[0].style.objectPosition = cropPos;
            }
        }
    }
});

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const { default: SlideUpDown } = await import(/* webpackChunkName: "vue-slide-up-down" */ 'vue-slide-up-down');
    const VueCookies = await import( /* webpackChunkName: "vue-cookies" */ 'vue-cookies');
    
    Vue.use(VueCookies);
    
    // Create our vue instance
    const vm = new Vue({
        el: "#app",
        delimiters: ['${', '}'],
        
        components: {
            'slide-up-down': SlideUpDown
        },
        
        data: {
            // Navigation
            mobileNavOpen: false,
            
            // Cookie consent
            showCookieConsent: false,
            
            // Radar graph
            graphOptions: {
                chart: {
                    id: 'header-chart',
                    toolbar: { show: false }
                },
                series: [
                    {
                        data: [45, 52, 38, 24, 33, 10]
                    },
                    {
                        data: [26, 21, 20, 6, 8, 15]
                    }
                ],
                legend: { show: false },
                fill: {
                    colors: ['#ffffff', '#138f3f'],
                    opacity: .2
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['#ffffff', '#138f3f'],
                    dashArray: 0
                },
                markers: {
                    size: 5,
                    strokeWidth: 0,
                    colors: ['#ffffff', '#138f3f'],
                },
                plotOptions: {
                    radar: {
                        polygons: {
                            strokeColor: '#ffffff',
                            fill: {
                                colors: []
                            }
                        }
                    }
                },
                xaxis: {
                    labels: {
                        show: false
                    }
                },
                yaxis: {
                    labels: {
                        show: true,
                        style: {
                            colors: ['#ffffff']
                        }
                    }
                }
            },
            graphSeries: [
                {
                    name: 'Series 1',
                    data: [75, 90, 82, 77, 92, 80, 68, 70],
                }, {
                    name: 'Series 2',
                    data: [120, 110, 95, 105, 115, 100, 90, 93],
                }
            ]
        },
        
        mounted() {
            // Init formie
            if (window.Formie) {
                window.Formie.initForms();
            }
            
            window.lozadObserver.observe();
            
            /* Show cookie consent with delay */
            setTimeout(() => {
                vm.showCookieConsent = true;
            }, 600);
    
            let formFields = document.querySelectorAll('.fui-field.animated');
            formFields.forEach(el => {
                const label = el.querySelector('label');
                const field = el.querySelector('input, textarea');
                if(field) {
                    const placeholder = field.placeholder;
            
                    field.addEventListener('focus', event => {
                        event.target.placeholder = '';
                        label.classList.add('show');
                    });
            
                    field.addEventListener('blur', event => {
                        let target = event.target;
                        target.placeholder = placeholder;
                        label.classList.remove('show');
                    });
                }
            });
        },
        
        methods: {
            confirmCookieConsent() {
                let vm = this;
                
                VueCookies.set('neoStretchCookieConsent', 'confirmed', { expires: 365 });
                setTimeout(() => {
                    vm.showCookieConsent = false;
                }, 200);
            },
        },
    });
    
    return vm;
};

// Execute async function
main().then((vm) => {
    // IE11 object-fit polyfill
    objectFitImages();
    
    let testimonialsSwiper = new Swiper('.swiper-container--testimonials', {
        loop: true,
        speed: 1000,
        centeredSlides: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });
});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
